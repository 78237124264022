import React, { useEffect, useRef, useState } from "react";
import Application from "../../../types/Application";
import Site from "../../../types/Site";
import { pageLoadErrorMessage } from "../../../common/errorMessages";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorMessage from "../../Common/Messages/ErrorMessage";
import SiteApplicationSelectionTableRow from "./SiteApplicationSelectionTableRow";
import {
    deletePlannedApplicationSite,
    fetchApplicationPeriod,
    fetchAvailableSiteDatesForSiteAndApplicationPeriod,
    fetchAvailableSiteTimeBySiteIdAndApplicationPeriodId,
    fetchPlannedApplicationSitesByApplicationPeriodId,
    fetchPlanningCommentsByApplicationPeriodId,
    fetchSiteById,
    fetchSitesWithPlannedOccasions,
    fetchViableApplicationsForSite,
    postPlannedApplicationSite
} from "../../../common/apiClient";
import SiteDetailsAccordionItem from "./SiteDetailsAccordionItem";
import AvailableSiteDateAccordionItem from "./AvailableSiteDateAccordionItem";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";
import PlanningModal from "../PlanningModal";
import { isSameDay } from "../../../common/dateUtils";
import SiteApplicationSelectionFilter, {
    SiteApplicationSelectionFilterOptions
} from "./SiteApplicationSelectionFilter";
import {
    applySiteApplicationSelectionFilter,
    getAdjustedAgeTagsWithSelectedOccasions
} from "../../../common/applicationUtils";
import { hasTwoOccasions, sortPlannedApplicationSites } from "../../../common/planningUtils";
import PlanningOverviewAccordionItem from "../PlanningOverviewAccordionItem";
import PlanningComment from "../../../types/PlanningComment";
import { toGroupedAgeTags } from "../../../common/stringUtils";

type SiteApplicationSelectionProps = {
    siteId: number;
    applicationPeriodId: number;
    canManagePlanning: boolean;
};

export default function SiteApplicationSelection({
    siteId,
    applicationPeriodId,
    canManagePlanning
}: SiteApplicationSelectionProps) {
    const [site, setSite] = useState<Site>();
    const [availableSiteDates, setAvailableSiteDates] = useState<AvailableSiteDate[]>();
    const [availableSiteTime, setAvailableSiteTime] = useState<AvailableSiteTime>();
    const [viableApplications, setViableApplications] = useState<Application[]>([]);
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [modalApplicationId, setModalApplicationId] = useState(0);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<SiteApplicationSelectionFilterOptions>();
    const [showPlanningModal, setShowPlanningModal] = useState(false);
    const [planningComments, setPlanningComments] = useState<PlanningComment[]>([]);
    const [modalDefaultDate, setModalDefaultDate] = useState<Date>();

    useEffect(() => {
        const fetchData = async () => {
            const [
                siteFetchResult,
                sitesWithPlannedOccasionsFetchResult,
                viableApplicationsFetchResult,
                availableSiteDatesFetchResult,
                applicationPeriodFetchResult,
                plannedApplicationSitesFetchResult,
                availableSiteTimeFetchResult,
                planningCommentsFetchResult
            ] = await Promise.all([
                fetchSiteById(siteId),
                fetchSitesWithPlannedOccasions(applicationPeriodId),
                fetchViableApplicationsForSite(siteId, applicationPeriodId),
                fetchAvailableSiteDatesForSiteAndApplicationPeriod(siteId, applicationPeriodId),
                fetchApplicationPeriod(applicationPeriodId),
                fetchPlannedApplicationSitesByApplicationPeriodId(applicationPeriodId),
                fetchAvailableSiteTimeBySiteIdAndApplicationPeriodId(siteId, applicationPeriodId),
                fetchPlanningCommentsByApplicationPeriodId(applicationPeriodId)
            ]);

            if (siteFetchResult.errorMessage) {
                console.error(siteFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (sitesWithPlannedOccasionsFetchResult.errorMessage) {
                console.error(sitesWithPlannedOccasionsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (viableApplicationsFetchResult.errorMessage) {
                console.error(viableApplicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteDatesFetchResult.errorMessage) {
                console.error(viableApplicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationSitesFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (availableSiteTimeFetchResult.errorMessage) {
                console.error(availableSiteTimeFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (planningCommentsFetchResult.errorMessage) {
                console.error(planningCommentsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            siteFetchResult.value.availableSiteDates = availableSiteDatesFetchResult.value;

            viableApplicationsFetchResult.value.forEach((a) => {
                const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(a, false);

                a.groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);
            });

            plannedApplicationSitesFetchResult.value.forEach((pas) => {
                pas.site = sitesWithPlannedOccasionsFetchResult.value.find((s) => s.id === pas.siteId);
                pas.application = viableApplicationsFetchResult.value.find((a) => a.id === pas.applicationId);
            });

            setSite(siteFetchResult.value);
            setViableApplications(viableApplicationsFetchResult.value);
            setAvailableSiteDates(availableSiteDatesFetchResult.value);
            setApplicationPeriod(applicationPeriodFetchResult.value);
            setPlannedApplicationSites(sortPlannedApplicationSites(plannedApplicationSitesFetchResult.value));
            setAvailableSiteTime(availableSiteTimeFetchResult.value);
            setPlanningComments(planningCommentsFetchResult.value);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const tableRowClickHandler = (application: Application) => {
        setModalApplicationId(application.id);
        setShowPlanningModal(true);
    };

    const hidePlanningModalHandler = () => {
        setShowPlanningModal(false);
        setModalDefaultDate(applicationPeriod.occasionsFrom);
    };

    const plannedApplicationSiteAddedHandler = async (addedPAS: PlannedApplicationSite) => {
        const temporaryId = 0;
        addedPAS.id = temporaryId;

        setPlannedApplicationSites((prevValues) => sortPlannedApplicationSites([...prevValues, addedPAS]));

        const numberOfAddedOccasions = hasTwoOccasions(addedPAS) ? 2 : 1;

        const plannedDateWasAvailableForSite = availableSiteDates.some(
            (asd) => asd.siteId === addedPAS.siteId && isSameDay(asd.availableDate, addedPAS.occasion1Starts)
        );

        if (plannedDateWasAvailableForSite) {
            setSite((prevValue) => ({
                ...prevValue,
                remainingNumberOfAvailableSiteDates: prevValue.remainingNumberOfAvailableSiteDates - 1
            }));
        }

        setViableApplications((prevValues) =>
            prevValues.map((a) =>
                a.id === addedPAS.applicationId
                    ? { ...a, numberOfPlannedOccasions: a.numberOfPlannedOccasions + numberOfAddedOccasions }
                    : a
            )
        );

        const postResult = await postPlannedApplicationSite(addedPAS);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            setErrorMessage(
                "Något gick fel när evenemanget skulle programläggas. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
            return;
        }

        const postedPlannedApplicationSiteId = postResult.value;

        setPlannedApplicationSites((prevValues) =>
            prevValues.map((pas) => (pas.id === temporaryId ? { ...pas, id: postedPlannedApplicationSiteId } : pas))
        );
    };

    const plannedApplicationSiteRemovedHandler = async (removedPAS: PlannedApplicationSite) => {
        setPlannedApplicationSites((prevValues) =>
            prevValues.filter(
                (pas) =>
                    pas.siteId !== removedPAS.siteId ||
                    pas.applicationId !== removedPAS.applicationId ||
                    !isSameDay(pas.occasion1Starts, removedPAS.occasion1Starts)
            )
        );

        const numberOfRemovedOccasions = hasTwoOccasions(removedPAS) ? 2 : 1;
        setViableApplications((prevValues) =>
            prevValues.map((a) =>
                a.id === removedPAS.applicationId
                    ? { ...a, numberOfPlannedOccasions: a.numberOfPlannedOccasions - numberOfRemovedOccasions }
                    : a
            )
        );

        const plannedDateWasAvailableForSite = availableSiteDates.some(
            (asd) => asd.siteId === removedPAS.siteId && isSameDay(asd.availableDate, removedPAS.occasion1Starts)
        );

        if (plannedDateWasAvailableForSite && plannedDateWasAvailableForSite) {
            setSite((prevValue) => ({
                ...prevValue,
                remainingNumberOfAvailableSiteDates: prevValue.remainingNumberOfAvailableSiteDates + 1
            }));
        }

        const deleteResult = await deletePlannedApplicationSite(removedPAS);

        if (deleteResult.errorMessage) {
            console.error(deleteResult.errorMessage);
            setErrorMessage(
                "Något gick fel när det programlagda tillfället skulle tas bort. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår."
            );
        }
    };

    const selectedFilterOptionsChangeHandler = (
        updatedProperty: keyof SiteApplicationSelectionFilterOptions,
        updatedValue: any
    ) => {
        setSelectedFilterOptions((prevValue) => ({ ...prevValue, [updatedProperty]: updatedValue }));
    };

    const overviewDateClickHandler = (clickedDate: Date, siteId: number, applicationId: number) => {
        setModalDefaultDate(clickedDate);
        setModalApplicationId(applicationId);
        setShowPlanningModal(true);
    };

    const pasForSite = plannedApplicationSites.filter((pas) => pas.siteId === siteId);

    const filteredApplications = applySiteApplicationSelectionFilter(viableApplications, selectedFilterOptions);

    const applicationTableRows = filteredApplications.map((a) => (
        <SiteApplicationSelectionTableRow application={a} key={a.id} onTableRowClick={() => tableRowClickHandler(a)} />
    ));

    const modalApplication = viableApplications.find((a) => a.id === modalApplicationId);

    return (
        <>
            <div className="container mt-4">
                <div className="mb-4">
                    <h2>
                        Programläggning: {site.name} - {site.roomName}
                    </h2>
                </div>

                <Accordion>
                    <AvailableSiteDateAccordionItem
                        availableSiteDates={availableSiteDates}
                        applicationPeriod={applicationPeriod}
                    />
                    <SiteDetailsAccordionItem site={site} />
                    <PlanningOverviewAccordionItem
                        plannedApplicationSites={pasForSite}
                        planningComments={planningComments}
                        applicationPeriod={applicationPeriod}
                        showExport={false}
                        onDateClick={overviewDateClickHandler}
                    />
                </Accordion>

                <section>
                    <h4>Programläggning</h4>
                    <div className="d-flex mb-2">
                        <div className="me-2">
                            <label className="form-label mb-0">
                                <span className="me-1">Tillgängliga datum</span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Det totala antalet datum som kan programläggas på spelplatsen.
                                        </Tooltip>
                                    }
                                >
                                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                </OverlayTrigger>
                            </label>
                            <input
                                className="form-control text-end pe-0"
                                type="number"
                                value={availableSiteDates.length}
                                disabled
                                style={{ width: "160px" }}
                            />
                        </div>
                        <div>
                            <label className="form-label mb-0">
                                <span className="me-1">Ej programlagda</span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Det återstående antalet datum som kan programläggas på spelplatsen.
                                        </Tooltip>
                                    }
                                >
                                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                </OverlayTrigger>
                            </label>
                            <input
                                className="form-control text-end pe-0"
                                type="number"
                                value={site.remainingNumberOfAvailableSiteDates}
                                disabled
                                style={{ width: "160px" }}
                            />
                        </div>
                    </div>
                    <SiteApplicationSelectionFilter
                        selectedFilterOptions={selectedFilterOptions}
                        onSelectedFilterOptionsChange={selectedFilterOptionsChangeHandler}
                        applicationPeriod={applicationPeriod}
                    />

                    <div className="border rounded">
                        <table className="table table-hover-effect">
                            <thead>
                                <tr>
                                    <th>Aktör</th>
                                    <th>Evenemang</th>
                                    <th>Målgrupper</th>
                                    <th>Matchning</th>
                                    <th style={{ width: "175px" }}>
                                        <span className="me-1">Tillfällen totalt</span>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Antalet tillfällen av evenemanget som valdes under urvalsprocessen.
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                        </OverlayTrigger>
                                    </th>
                                    <th style={{ width: "175px" }}>
                                        <span className="me-1">Ej programlagda</span>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Antalet tillfällen av evenemanget som fortfarande måste
                                                    programläggas.
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                        </OverlayTrigger>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{applicationTableRows}</tbody>
                        </table>
                    </div>
                </section>
            </div>
            <PlanningModal
                application={modalApplication}
                site={site}
                availableSiteDates={availableSiteDates}
                availableSiteTime={availableSiteTime}
                plannedApplicationSites={plannedApplicationSites}
                onAddPlannedApplicationSite={plannedApplicationSiteAddedHandler}
                onRemovePlannedApplicationSite={plannedApplicationSiteRemovedHandler}
                applicationPeriod={applicationPeriod}
                show={showPlanningModal}
                onHide={hidePlanningModalHandler}
                planningComments={planningComments}
                onError={setErrorMessage}
                onPlanningCommentsChange={setPlanningComments}
                canManagePlanning={canManagePlanning}
                onPlannedApplicationSitesChange={setPlannedApplicationSites}
                defaultDate={modalDefaultDate}
            />
        </>
    );
}
