import React, { useState } from "react";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import PlanningOverviewTableRow from "./PlanningOverviewTableRow";
import { applyPlanningOverviewFilter } from "../../common/planningUtils";
import { isSameDay, removeTimeFromDate } from "../../common/dateUtils";
import { Accordion } from "react-bootstrap";
import PlanningComment from "../../types/PlanningComment";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import { HexColor } from "../../common/enums";

type PlanningOverviewAccordionItemProps = {
    plannedApplicationSites: PlannedApplicationSite[];
    planningComments: PlanningComment[];
    applicationPeriod: ApplicationPeriod;
    showExport: boolean;
    onDateClick: (date: Date, siteId: number, applicationId: number) => void;
};

export type SelectedPlanningOverviewOptions = {
    siteId: number;
    cultureProviderId: number;
    applicationId: number;
    searchText: string;
    fromDate: Date;
    toDate: Date;
};

function PlanningOverviewAccordionItem({
    plannedApplicationSites,
    planningComments,
    applicationPeriod,
    showExport,
    onDateClick
}: PlanningOverviewAccordionItemProps) {
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<SelectedPlanningOverviewOptions>();

    const selectedFilterOptionsChangeHandler = (value: any, updatedProperty: keyof SelectedPlanningOverviewOptions) => {
        if (!value) {
            setSelectedFilterOptions((prevValue) => {
                const updatedOptions = { ...prevValue };
                delete updatedOptions[updatedProperty];

                return updatedOptions;
            });
        } else {
            setSelectedFilterOptions((prevValue) => ({ ...prevValue, [updatedProperty]: value }));
        }
    };

    plannedApplicationSites = plannedApplicationSites.sort(
        (a, b) => a.occasion1Starts.getTime() - b.occasion1Starts.getTime()
    );

    const filteredPAS = applyPlanningOverviewFilter(selectedFilterOptions, plannedApplicationSites);

    const planningOverviewTableRows = filteredPAS.map((pas) => {
        const planningComment = planningComments.find(
            (pc) =>
                pc.siteId === pas.siteId &&
                pc.applicationId === pas.applicationId &&
                isSameDay(pc.date, pas.occasion1Starts)
        );

        return <PlanningOverviewTableRow plannedApplicationSite={pas} planningComment={planningComment} key={pas.id} onDateClick={onDateClick} />;
    });

    const siteOptions = plannedApplicationSites
        .filter((pas, index, self) => self.findIndex((p) => p.siteId === pas.siteId) === index)
        .sort((p1, p2) => p1.site.name.toLowerCase().localeCompare(p2.site.name.toLowerCase()))
        .map((pas) => (
            <option key={pas.siteId} value={pas.siteId}>
                {pas.site.name}
            </option>
        ));

    const actorOptions = plannedApplicationSites
        .filter(
            (pas, index, self) =>
                self.findIndex((p) => p.application.cultureProviderId === pas.application.cultureProviderId) === index
        )
        .sort((p1, p2) => p1.application.actorName.toLowerCase().localeCompare(p2.application.actorName.toLowerCase()))
        .map((pas) => (
            <option key={pas.application.cultureProviderId} value={pas.application.cultureProviderId}>
                {pas.application.actorName}
            </option>
        ));

    const applicationOptions = plannedApplicationSites
        .filter((pas, index, self) => self.findIndex((p) => p.applicationId === pas.applicationId) === index)
        .sort((p1, p2) =>
            p1.application.document.eventName
                .toLowerCase()
                .localeCompare(p2.application.document.eventName.toLowerCase())
        )
        .map((pas) => (
            <option key={pas.applicationId} value={pas.applicationId}>
                {pas.application.document.eventName}
            </option>
        ));

    const queryParamsObject: any = {
        ...selectedFilterOptions
    };

    if (selectedFilterOptions && selectedFilterOptions.fromDate) {
        queryParamsObject.fromDate = selectedFilterOptions.fromDate.toDateString();
    }

    if (selectedFilterOptions && selectedFilterOptions.toDate) {
        queryParamsObject.toDate = selectedFilterOptions.toDate.toDateString();
    }

    const exportQueryParams = new URLSearchParams(queryParamsObject).toString();

    return (
        <Accordion.Item eventKey="2">
            <Accordion.Header>
                <span className="fw-bold">Översikt</span>
            </Accordion.Header>
            <Accordion.Body className="p-0">
                <div className="d-flex justify-content-end">
                    {showExport && (
                        <a
                            className="btn btn-success m-2"
                            href={`/api/applicationPeriod/${applicationPeriod.id}/planning/export?${exportQueryParams}`}
                        >
                            <i className="bi bi-file-excel-fill me-1"></i>
                            Ladda ner excelfil
                        </a>
                    )}
                </div>
                <div className="row mb-2">
                    <div className="col-md-2 form-group">
                        <label className="form-label fw-bold">Fritextsök</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => selectedFilterOptionsChangeHandler(e.target.value, "searchText")}
                        />
                    </div>
                    <div className="col-md-2 form-group">
                        <label className="form-label fw-bold">Spelplats</label>
                        <select
                            className="form-select"
                            value={selectedFilterOptions ? selectedFilterOptions.siteId : ""}
                            onChange={(e) =>
                                selectedFilterOptionsChangeHandler(
                                    e.target.value ? Number.parseInt(e.target.value) : "",
                                    "siteId"
                                )
                            }
                        >
                            <option value={""}>- Alla spelplatser -</option>
                            {siteOptions}
                        </select>
                    </div>
                    <div className="col-md-2 form-group">
                        <label className="form-label fw-bold">Aktör</label>
                        <select
                            className="form-select"
                            value={selectedFilterOptions ? selectedFilterOptions.cultureProviderId : ""}
                            onChange={(e) =>
                                selectedFilterOptionsChangeHandler(
                                    e.target.value ? Number.parseInt(e.target.value) : "",
                                    "cultureProviderId"
                                )
                            }
                        >
                            <option value={""}>- Alla aktörer -</option>
                            {actorOptions}
                        </select>
                    </div>
                    <div className="col-md-2 form-group">
                        <label className="form-label fw-bold">Evenemang</label>
                        <select
                            className="form-select"
                            value={selectedFilterOptions ? selectedFilterOptions.applicationId : ""}
                            onChange={(e) =>
                                selectedFilterOptionsChangeHandler(
                                    e.target.value ? Number.parseInt(e.target.value) : "",
                                    "applicationId"
                                )
                            }
                        >
                            <option value={""}>- Alla evenemang -</option>
                            {applicationOptions}
                        </select>
                    </div>
                    <div className="col-md-2 form-group">
                        <label className="form-label fw-bold">Datum från</label>
                        <input
                            className="form-control"
                            type="date"
                            onChange={(e) =>
                                selectedFilterOptionsChangeHandler(
                                    e.target.value ? removeTimeFromDate(new Date(e.target.value)) : "",
                                    "fromDate"
                                )
                            }
                        />
                    </div>
                    <div className="col-md-2 form-group">
                        <label className="form-label fw-bold">Datum till</label>
                        <input
                            className="form-control"
                            type="date"
                            onChange={(e) =>
                                selectedFilterOptionsChangeHandler(
                                    e.target.value ? removeTimeFromDate(new Date(e.target.value)) : "",
                                    "toDate"
                                )
                            }
                        />
                    </div>
                </div>

                <table className="table accordion-table m-0">
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: HexColor.Blue }}>Spelplats</th>
                            <th>Platser</th>
                            <th style={{ backgroundColor: HexColor.Green }}>Dag</th>
                            <th style={{ backgroundColor: HexColor.Green }}>Datum</th>
                            <th style={{ backgroundColor: HexColor.Green }}>Vecka</th>
                            <th style={{ backgroundColor: HexColor.Yellow }}>Tid 1</th>
                            <th style={{ backgroundColor: HexColor.Yellow }}>Tid 2</th>
                            <th style={{ backgroundColor: HexColor.Red }}>Aktör</th>
                            <th style={{ backgroundColor: HexColor.Purple }}>Evenemang</th>
                            <th>Målgrupp</th>
                        </tr>
                    </thead>
                    <tbody>{planningOverviewTableRows}</tbody>
                </table>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default PlanningOverviewAccordionItem;
