import { ApplicationSiteSelectionFilterOptions } from "../components/Planning/ApplicationPlanning/ApplicationSiteSelectionFilter";
import { SitePlanningFilterOptions } from "../components/Planning/SitePlanning/SitePlanningFilter";
import Site from "../types/Site";
import { isSameDay } from "./dateUtils";
import { SitePlanningStatus } from "./enums";

export function applySitePlanningFilter(sites: Site[], filter: SitePlanningFilterOptions): Site[] {
    if (!filter) return sites;

    let filteredSites = sites;

    if (filter.searchText) {
        const lowerCaseText = filter.searchText.toLowerCase();

        filteredSites = filteredSites.filter(
            (s) =>
                s.name.toLowerCase().includes(lowerCaseText) || s.roomName.toLocaleLowerCase().includes(lowerCaseText)
        );
    }

    if (filter.sitePlanningStatus === SitePlanningStatus.HasNoAvailableDates) {
        filteredSites = filteredSites.filter((s) => s.remainingNumberOfAvailableSiteDates === 0);
    } else if (filter.sitePlanningStatus === SitePlanningStatus.HasAvailableDates) {
        filteredSites = filteredSites.filter((s) => s.remainingNumberOfAvailableSiteDates > 0);
    }

    if (filter.availableDates.length > 0) {
        filteredSites = filteredSites.filter((s) =>
            filter.availableDates.some((d) => s.availableSiteDates.some((asd) => isSameDay(d, asd.availableDate)))
        );
    }

    return filteredSites;
}

export function applyApplicationSiteSelectionFilter(
    sites: Site[],
    filter: ApplicationSiteSelectionFilterOptions
): Site[] {
    if (!filter) return sites;

    let filteredSites = sites;

    if (filter.searchText) {
        const lowerCaseText = filter.searchText.toLowerCase();

        filteredSites = filteredSites.filter(
            (s) =>
                s.name.toLowerCase().includes(lowerCaseText) || s.roomName.toLocaleLowerCase().includes(lowerCaseText)
        );
    }

    if (filter.cityArea) {
        filteredSites = filteredSites.filter((s) => s.cityArea === filter.cityArea);
    }

    if (filter.availableDates.length > 0) {
        filteredSites = filteredSites.filter((s) =>
            filter.availableDates.some((d) => s.availableSiteDates.some((asd) => isSameDay(d, asd.availableDate)))
        );
    }

    return filteredSites;
}
