import React from "react";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import { getDefaultNumberOfSeats, getNumberOfSeats, hasTwoOccasions } from "../../common/planningUtils";
import { getTimeSpanString, getWeekDayName, getWeekNumber } from "../../common/dateUtils";
import { HexColor, SiteType } from "../../common/enums";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PlanningComment from "../../types/PlanningComment";

type PlanningOverviewTableRowProps = {
    plannedApplicationSite: PlannedApplicationSite;
    planningComment: PlanningComment | undefined;
    onDateClick: (date: Date, siteId: number, applicationId: number) => void;
};

function PlanningOverviewTableRow({
    plannedApplicationSite: pas,
    planningComment,
    onDateClick
}: PlanningOverviewTableRowProps) {
    const site = pas.site;
    const application = pas.application;

    const twoOccasionsPlanned = hasTwoOccasions(pas);

    const timeSpan1 = getTimeSpanString(pas.occasion1Starts, pas.occasion1Ends);
    const timeSpan2 = twoOccasionsPlanned ? getTimeSpanString(pas.occasion2Starts, pas.occasion2Ends) : "";

    const numberOfSeats = getNumberOfSeats(pas);

    return (
        <tr>
            <td style={{ whiteSpace: "nowrap", backgroundColor: HexColor.Blue }}>
                {pas.dateIsConfirmed && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Datumet har bekräftats</Tooltip>}>
                        <i className="bi bi-check me-1 text-success"></i>
                    </OverlayTrigger>
                )}
                {planningComment && planningComment.comment && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>{getCommentPreview(planningComment)}</Tooltip>}>
                        <i className="bi bi-chat-right-text-fill me-1"></i>
                    </OverlayTrigger>
                )}
                <span>{site.name}</span>
            </td>
            <td>{numberOfSeats}</td>
            <td style={{ backgroundColor: HexColor.Green }}>{getWeekDayName(pas.occasion1Starts)}</td>
            <td style={{ whiteSpace: "nowrap", backgroundColor: HexColor.Green }}>
                <button
                    className="btn btn-link"
                    onClick={() => onDateClick(new Date(pas.occasion1Starts), pas.siteId, pas.applicationId)}
                >
                    {pas.occasion1Starts.toLocaleDateString()}
                </button>
            </td>
            <td style={{ backgroundColor: HexColor.Green }}>{getWeekNumber(pas.occasion1Starts)}</td>
            <td style={{ backgroundColor: HexColor.Yellow }}>{timeSpan1}</td>
            <td style={{ backgroundColor: HexColor.Yellow }}>{timeSpan2}</td>
            <td style={{ backgroundColor: HexColor.Red }}>{application.actorName}</td>
            <td style={{ backgroundColor: HexColor.Purple }}>{application.document.eventName}</td>
            <td>{pas.intendedAudience ?? application.groupedAgeTags.join(", ")}</td>
        </tr>
    );
}

function getCommentPreview(planningComment: PlanningComment): string {
    const commentPreviewMaxLength = 256;
    if (planningComment.comment.length > commentPreviewMaxLength) {
        return planningComment.comment.slice(0, commentPreviewMaxLength) + "...";
    } else return planningComment.comment;
}

export default PlanningOverviewTableRow;
